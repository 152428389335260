<template>
  <div>
    <el-table :data="tableData" stripe border>
      <el-table-column prop="name" label="集群名称"></el-table-column>
      <el-table-column prop="servers" label="集群地址"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            title="确定删除吗?"
            icon="el-icon-info"
            icon-color="red"
            @confirm="deleteCluster(scope.row.id)"
          >
            <el-button size="mini" type="danger" slot="reference"
              >删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="handleClick" style="margin-top: 5px"
      >添加环境</el-button
    >

    <el-dialog
      title="添加kafka地址"
      :visible.sync="dialogFormVisible"
      width="600px"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="name" label="集群名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="servers" label="集群地址">
          <el-input
            placeholder="172.18.0.1:9092,172.18.0.2:9092,172.18.0.3:9092"
            v-model="form.servers"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCluster">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "config",
  data() {
    return {
      tableData: undefined,
      dialogFormVisible: false,
      form: {
        name: undefined,
        servers: undefined,
      },
      warning: false,
      rules: {
        name: [{ required: true, message: "请输入集群名称", trigger: "blur" }],
        servers: [
          {
            validator: (rule, value, callback) => {
              if (value == null || value == undefined || value == "") {
                return callback(new Error("请输入集群地址"));
              }
              const addresses = value.split(",");
              for (let address of addresses) {
                const str = address.split(":");
                let regexIP =
                  /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/;
                let regexPort = /^[0-9]{1,4}/g;
                if (!regexIP.test(str[0]) || !regexPort.test(str[1])) {
                  console.log(123);
                  return callback(new Error("集群地址有误"));
                }
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getAllCluster()
  },
  methods: {
    async deleteCluster(id) {
      const { data } = await this.$http({
        url: this.$http.adornUrl("/kafka/deleteCluster"),
        method: "post",
        params: this.$http.adornParams({
          id,
        }),
      });
      if (data?.code == 500) {
        this.$message.error(data.msg);
      } else {
        this.$message.success("删除成功");
      }
      this.getAllCluster();
    },
    async getAllCluster() {
      const { data } = await this.$http({
        url: this.$http.adornUrl("/kafka/getAllCluster"),
        methods: "get",
      });
      this.tableData = data.clusters;
    },
    addCluster() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          this.$http({
            url: this.$http.adornUrl("/kafka/addCluster"),
            method: "post",
            data: this.$http.adornData({
              name: this.name,
              servers: this.servers,
            }),
          }).then(({ data }) => {
            if (data?.code == 500) {
              this.$message.error(data.msg);
            } else {
              this.$message.success("添加kafka环境成功");
            }
            this.getAllCluster();
          });
        }
      });
    },
    handleClick() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
      });
    },
  },
};
</script>

<style scoped></style>
