<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="管道管理" name="topic">
        <topic v-if="activeName == 'topic'"></topic>
      </el-tab-pane>
      <el-tab-pane label="查看拓扑图" name="topology">
        <topology v-if="activeName == 'topology'"></topology>
      </el-tab-pane>
      <el-tab-pane label="传输环境管理" name="config">
        <config v-if="activeName == 'config'"></config>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import topic from "./manage/topic";
import config from "./manage/config.vue";
import topology from "./manage/topology";

export default {
  name: "manage",
  data() {
    return {
      activeName: "topic",
    };
  },
  created() {},
  methods: {
    handleClick() {

    },
  },
  components: {
    topic,
    config,
    topology,
  },
};
</script>

<style scoped>
</style>
